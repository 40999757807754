import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { StoreState } from '../types'
import { fetchElection } from '@ballotready/redux-api-client'

export default (): ThunkAction<
  void,
  StoreState,
  null,
  Action<string>
> => async (dispatch, getState) => {
  const { ballot } = getState()

  if (ballot.election_id) {
    return dispatch(fetchElection(ballot.election_id))
  }
}
