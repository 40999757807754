import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, withRouter } from 'react-router'
import Loadable from 'react-loadable'
import LoadingComponent from './LoadingComponent/LoadingComponent'
import mainContainer from './mainContainer'
import NotFoundScene from '../NotFoundScene/NotFoundScene'
import SocialHelmet from './SocialHelmet/SocialHelmet'
import DebugPanel from './DebugPanel/DebugPanel'
import getPathForApp from 'utils/getPathForApp'

const AsyncDashboardApp = Loadable({
  loader: () => import('../dashboard/DashboardApp/DashboardApp'),
  loading: LoadingComponent,
})

const AsyncDirectoryApp = Loadable({
  loader: () => import('../directory/DirectoryApp/DirectoryApp'),
  loading: LoadingComponent,
})

const AsyncCheckRegistrationApp = Loadable({
  loader: () =>
    import('../check_registration/CheckRegistrationApp/CheckRegistrationApp'),
  loading: LoadingComponent,
})

const AsyncElectionCenter = Loadable({
  loader: () =>
    import('../election_center/ElectionCenterApp/ElectionCenterApp'),
  loading: LoadingComponent,
})

const AsyncExploreByMap = Loadable({
  loader: () => import('../explore_by_map/ExploreByMapApp'),
  loading: LoadingComponent,
})

const AsyncHomeApp = Loadable({
  loader: () => import('../home/HomeApp/HomeApp'),
  loading: LoadingComponent,
})

const AsyncMaptvApp = Loadable({
  loader: () => import('../maptv/MaptvApp/MaptvApp'),
  loading: LoadingComponent,
})

const AsyncOfficeHoldersApp = Loadable({
  loader: () => import('../office_holders/OfficeHoldersApp'),
  loading: LoadingComponent,
})

const AsyncRequestBallotApp = Loadable({
  loader: () =>
    import('../request_ballot/RequestBallotApp/RequestBallotApp.tsx'),
  loading: LoadingComponent,
})

const AsyncPledgeApp = Loadable({
  loader: () => import('../pledge/PledgeApp/PledgeApp'),
  loading: LoadingComponent,
})

const AsyncSearchApp = Loadable({
  loader: () => import('../search/SearchApp/SearchApp'),
  loading: LoadingComponent,
})

const AsyncSharedBallotApp = Loadable({
  loader: () => import('../shared_ballot/SharedBallotApp/SharedBallotApp'),
  loading: LoadingComponent,
})

const AsyncSlateCardApp = Loadable({
  loader: () => import('../slate/SlateApp/SlateApp'),
  loading: LoadingComponent,
})

const AsyncSuperSlateCardApp = Loadable({
  loader: () => import('../super_slate/SuperSlateApp/SuperSlateApp'),
  loading: LoadingComponent,
})

const AsyncVoterGuideApp = Loadable({
  loader: () => import('../voter_guide/VoterGuideApp/VoterGuideApp'),
  loading: LoadingComponent,
})

const AsyncWidgetApp = Loadable({
  loader: () => import('../widget/WidgetApp/WidgetApp'),
  loading: LoadingComponent,
})

const AsyncSandboxApp = Loadable({
  loader: () => import('../sandbox/SandboxApp'),
  loading: LoadingComponent,
})

class Main extends React.Component {
  render() {
    const {
      appConfig: {
        debug,
        enableDashboard,
        enableDirectory,
        enableElectionCenter,
        enableMaptv,
        enablePledge,
        enableShareableBallots,
        enableSlateCard,
        enableSuperSlateCard,
        enableRegistrationCheck,
        enableRequestBallot,
        enableVoterGuide,
        featureFlags,
        landingPage,
        siteTitle,
        socialGraph,
        tenant,
      },
    } = this.props

    const enableExploreByMap = featureFlags.includes('EXPLORE_BY_MAP')
    const enableOfficeHolders = featureFlags.includes('OFFICE_HOLDERS')
    const enableSearch = featureFlags.includes('ELECTION_CENTER_SEARCH')

    return (
      <div className="Main">
        <Helmet>
          <title>
            {siteTitle} - {tenant.name}
          </title>
          {process.env.REACT_APP_INTERCOM_APP_ID && (
            <script>{`window.intercomSettings = { app_id: "${process.env.REACT_APP_INTERCOM_APP_ID}" };`}</script>
          )}
          {process.env.REACT_APP_INTERCOM_APP_ID && (
            <script>{`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}</script>
          )}
        </Helmet>

        <SocialHelmet attributes={socialGraph} />

        {debug && <DebugPanel />}

        <Switch>
          {enableShareableBallots && (
            <Route
              path={`${getPathForApp('shareable_ballot')}:shareToken`}
              component={AsyncSharedBallotApp}
            />
          )}
          {enableSlateCard && <Route path="/s" component={AsyncSlateCardApp} />}
          {enableSuperSlateCard && (
            <Route
              path={getPathForApp('super_slate')}
              component={AsyncSuperSlateCardApp}
            />
          )}
          {enableVoterGuide && (
            <Route
              path={getPathForApp('voter_guide')}
              component={AsyncVoterGuideApp}
            />
          )}
          {enableMaptv && (
            <Route path={getPathForApp('maptv')} component={AsyncMaptvApp} />
          )}
          {enableRequestBallot && (
            <Route
              path={getPathForApp('request_ballot')}
              component={AsyncRequestBallotApp}
            />
          )}
          {enableRegistrationCheck && (
            <Route
              path={getPathForApp('check_registration')}
              component={AsyncCheckRegistrationApp}
            />
          )}
          {enablePledge && (
            <Route path={getPathForApp('pledge')} component={AsyncPledgeApp} />
          )}
          <Route path={getPathForApp('widget')} component={AsyncWidgetApp} />
          {landingPage === 'home' && (
            <Route exact path="/" component={AsyncHomeApp} />
          )}
          <Route path={getPathForApp('user')} component={AsyncHomeApp} />
          {enableDashboard && (
            <Route
              path={getPathForApp('dashboard')}
              component={AsyncDashboardApp}
            />
          )}
          {enableSearch && (
            <Route path={getPathForApp('search')} component={AsyncSearchApp} />
          )}

          {enableExploreByMap && (
            <Route
              path={getPathForApp('explore_by_map')}
              component={AsyncExploreByMap}
            />
          )}

          {enableOfficeHolders && (
            <Route
              path={getPathForApp('office_holders')}
              component={AsyncOfficeHoldersApp}
            />
          )}

          {enableElectionCenter && (
            <Route
              path={getPathForApp('election_center')}
              component={AsyncElectionCenter}
            />
          )}

          {enableDirectory && (
            <Route
              path={getPathForApp('seo_us_states')}
              component={AsyncDirectoryApp}
            />
          )}
          {debug && (
            <Route
              path={getPathForApp('sandbox')}
              component={AsyncSandboxApp}
            />
          )}

          <Route component={NotFoundScene} />
        </Switch>
      </div>
    )
  }
}

export default mainContainer(withRouter(Main))
