import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import AddressForm from 'components/AddressForm/AddressForm'
import Modal from 'components/sparkles/Modal/Modal'
import './AddressModal.scss'
import { Ballot } from '@ballotready/redux-api-client'

interface Props {
  ballot?: Ballot
  shouldRedirect?: boolean
  onUpdateAddress?: () => void
  onClose: (isSubmit: boolean) => void
}

const AddressModal: React.FC<Props> = ({
  ballot,
  shouldRedirect = true,
  onUpdateAddress,
  onClose,
}) => {
  const { t } = useTranslation()

  const handleSubmit = () => {
    onUpdateAddress && onUpdateAddress()
    onClose(true)
  }

  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={true}
      onClose={() => onClose(false)}
    >
      {ballot ? (
        <div className="AddressModal__CurrentAddress">
          <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
          {t('AddressModal.currentAddress')}:<strong>{ballot.address}</strong>
        </div>
      ) : (
        <>
          <h2>{t('AddressModal.updateAddress')}</h2>
          {t('AddressModal.explanation')}
        </>
      )}

      <AddressForm
        onSubmit={handleSubmit}
        showLabels={true}
        redirect={shouldRedirect}
      />
    </Modal>
  )
}

export default AddressModal
