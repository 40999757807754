import { combineReducers } from 'redux'
import { createResponsiveStateReducer } from 'redux-responsive'
import {
  authReducer as auth,
  ballotReducer as ballot,
  ballotPlacesReducer as ballotPlaces,
  candidatesByIdReducer as candidatesById,
  candidatesByPositionReducer as candidatesByPosition,
  districtsReducer as districts,
  electionReducer as election,
  electionPartiesReducer as parties,
  electionsReducer as elections,
  endorsedCandidatesReducer as endorsedCandidates,
  fetchCounterReducer as fetchCounter,
  formReducer as form,
  issuesByPositionReducer as issuesByPosition,
  measuresReducer as measures,
  normalizedPositionReducer as mapResults,
  officeHoldersReducer as officeHolders,
  pollingDaysReducer as pollingDays,
  pollingPlacesReducer as pollingPlaces,
  positionsByStateReducer as positionsByState,
  positionsByCountyReducer as positionsByCounty,
  positionsReducer as positions,
  selectionsReducer as selections,
  sharedBallotReducer as sharedBallot,
  statesReducer as states,
  subscriptionReducer as subscriptionCall,
  votingPlanReducer as votingPlan,
} from '@ballotready/redux-api-client'
import appConfig from 'reducers/appConfigReducer'
import authModalIsOpen from 'reducers/authModalIsOpenReducer'
import currentCandidate from 'reducers/currentCandidateReducer'
import currentIssue from 'reducers/currentIssueReducer'
import currentMeasure from 'reducers/currentMeasureReducer'
import currentPosition from 'reducers/currentPositionReducer'
import electionProgression from 'reducers/electionProgressionReducer'
import electionCenter from 'reducers/electionCenterReducer'
import filters from 'reducers/filtersReducer'
import lead from 'reducers/leadReducer'
import maptvSelections from 'reducers/maptvSelectionsReducer'
import notifications from 'reducers/notificationsReducer'
import pingCount from 'reducers/pingCountReducer'
import reminderChannels from 'reducers/reminderChannelsReducer'
import requestBallotSelections from 'reducers/requestBallotSelectionsReducer'
import searchResults from 'reducers/searchResultsReducer'
import selectedTags from 'reducers/selectedTagsReducer'
import showGlobalAlert from 'reducers/showGlobalAlertReducer'
import showLeadBar from 'reducers/showLeadBarReducer'
import showShareableBallotBanner from 'reducers/showShareableBallotBannerReducer'
import showSignUpPrompt from 'reducers/showSignUpPromptReducer'
import stepStack from 'reducers/stepStackReducer'
import usStates from 'reducers/usStatesReducer'
import vbmReceiver from 'reducers/vbmReceiverReducer'
import { AppConfig } from 'appConfig'

export default (config: AppConfig) => {
  // Shared reducers
  let reducers = {
    appConfig,
    auth,
    authModalIsOpen,
    ballot,
    ballotPlaces,
    browser: createResponsiveStateReducer({
      extraSmall: 479,
      small: 767,
      medium: 991,
      large: 1099,
      extraLarge: 1150,
    }),
    candidatesById,
    candidatesByPosition,
    currentCandidate,
    currentIssue,
    currentMeasure,
    currentPosition,
    districts,
    election,
    electionCenter,
    electionProgression,
    elections,
    endorsedCandidates,
    fetchCounter,
    form,
    issuesByPosition,
    lead,
    maptvSelections,
    measures,
    notifications,
    officeHolders,
    parties,
    pingCount,
    pollingDays,
    pollingPlaces,
    positions,
    positionsByCounty,
    positionsByState,
    reminderChannels,
    requestBallotSelections,
    selections,
    sharedBallot,
    showGlobalAlert,
    showLeadBar,
    showShareableBallotBanner,
    showSignUpPrompt,
    states,
    stepStack,
    usStates,
    vbmReceiver,
    votingPlan,
    subscriptionCall,
  }

  if (config.enableSuperSlateCard) {
    reducers = Object.assign({}, reducers, {
      filters,
      selectedTags,
    })
  }

  if (config.featureFlags.find(value => value === 'ELECTION_CENTER_SEARCH')) {
    reducers = Object.assign({}, reducers, { searchResults })
  }

  if (config.featureFlags.find(value => value === 'EXPLORE_BY_MAP')) {
    reducers = Object.assign({}, reducers, { mapResults })
  }
  return combineReducers(reducers)
}
