/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }] */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Button } from '@ballotready/sparkles'
import Modal from '../sparkles/Modal/Modal'
import Logo from '../sparkles/Logo/Logo'
import { Notification } from '@ballotready/sparkles'
import authModalContainer from './authModalContainer'
import FacebookLoginButton from './FacebookLoginButton/FacebookLoginButton'
import GoogleLoginButton from './GoogleLoginButton/GoogleLoginButton'
import './AuthModal.css'
import AuthForm from './AuthForm/AuthForm'
import { SectionSeparatorLine } from 'components/sparkles/SectionSeparatorLine/SectionSeparatorLine'

export class AuthModal extends React.Component {
  static propTypes = {
    btnSize: PropTypes.string,
    btnText: PropTypes.string,
    logoUrl: PropTypes.string,
    onLoginFailed: PropTypes.func,
    onLoginSuccess: PropTypes.func,
    signUpEnabled: PropTypes.bool,
  }

  static defaultProps = {
    btnOutline: true,
    btnSize: 'sm',
    btnVariant: 'primary',
    signUpEnabled: true,
  }

  state = {
    isModalOpen: false,
    mode: 'sign_in',
  }

  constructor(props) {
    super(props)

    this.modalRef = null
  }

  componentDidUpdate(_prevProps, prevState) {
    // Scroll to top of modal on mode change
    if (this.modalRef && prevState.mode !== this.state.mode) {
      this.modalRef.scrollTop = 0
    }
  }

  setModalState(isOpen) {
    this.setState({ isModalOpen: isOpen })
    this.props.setAuthModalState(isOpen)
  }

  onCloseModal() {
    this.setState({ mode: 'sign_in' })
    this.setModalState(false)
  }

  render() {
    const {
      btnSize,
      btnText,
      error,
      logoUrl,
      onLoginFailed,
      onLoginSuccess,
      signUpEnabled,
      t,
    } = this.props

    const { isModalOpen, mode } = this.state

    let headerText
    switch (mode) {
      case 'reset_pw':
        headerText = <p>{t('AuthModal.resetPwText')}</p>
        break
      case 'sign_up':
        headerText = <p>{t('AuthModal.signUpText')}</p>
        break
      default:
        headerText = <p>{t('AuthModal.signInText')}</p>
    }

    return (
      <div className="AuthModal__Container">
        <Button
          className="AuthModal__Btn"
          onClick={() => this.setModalState(true)}
          outline
          size={btnSize}
          variant="primary"
        >
          {btnText || t('AuthModal.signIn')}
        </Button>

        <Modal
          appElement={document.getElementById('root')}
          className="AuthModal"
          contentRef={node => (this.modalRef = node)}
          isOpen={isModalOpen}
          onClose={() => this.onCloseModal()}
        >
          <header className="AuthModal__header">
            <Logo src={logoUrl} />
            {headerText}
            {error && error.message && (
              <Notification className="AuthModal__Error" variant="danger">
                {error.message}
              </Notification>
            )}
          </header>

          {mode !== 'reset_pw' && (
            <section className="AuthModal__Social">
              <FacebookLoginButton
                onLoginFailed={onLoginFailed}
                onLoginSuccess={user => onLoginSuccess('facebook', user)}
              >
                Facebook
              </FacebookLoginButton>

              <GoogleLoginButton
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onLoginFailed={onLoginFailed}
                onLoginSuccess={user => onLoginSuccess('google', user)}
              >
                Google
              </GoogleLoginButton>
            </section>
          )}

          <section className="AuthModal__Email">
            {mode !== 'reset_pw' && (
              <SectionSeparatorLine text={t('AuthModal.or')} />
            )}
            <AuthForm
              mode={mode}
              onForgotPasswordClick={() => this.setState({ mode: 'reset_pw' })}
            />
          </section>

          {signUpEnabled && (
            <footer>
              {mode === 'sign_up' ? (
                <section className="AuthModal__StateSwitcher">
                  <div>{t('AuthModal.haveAccount')}</div>
                  <Button
                    onClick={() => this.setState({ mode: 'sign_in' })}
                    outline
                    variant="primary"
                  >
                    {t('AuthModal.signIn')}
                  </Button>
                </section>
              ) : (
                <section className="AuthModal__StateSwitcher">
                  <div>{t('AuthModal.createAccount')}</div>
                  <Button
                    onClick={() => this.setState({ mode: 'sign_up' })}
                    outline
                    variant="primary"
                  >
                    {t('AuthModal.signUp')}
                  </Button>
                </section>
              )}
            </footer>
          )}
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(withRouter(authModalContainer(AuthModal)))
